<template>
  <div class="download_wrap" >
    <div class="phone_wrap" >
      <div class="content"> </div>
    </div>
    <div class="download_content" >
      <div class="download_btn" @click="downloadIphone" >
        <img src="./img/iphone.png" class="iphone_icon" />
        iphone 下载
      </div>

      <div class="download_btn" @click="downloadAndroid" >
        <img src="./img/android.png" class="android_icon" />
        android 下载
      </div>

      <div class="invitation_code" v-if="name !== '-1' || code !== '-1'" >
        {{ name }} <div class="line" > </div> 邀请码：{{ code }}
      </div>
    </div>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import { Toast } from 'vant'
import _AP from "@/utils/ap";
export default {
  name: 'download',
  data() {
    return {
      name: this.$route.params.name,
      code: this.$route.params.code
    }
  },
  methods: {
    downloadIphone() {
      var userAgent = navigator.userAgent;
      var isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if(isiOS) {
        _AP.install('https://apps.apple.com/cn/app/id1668708327');
        // _AP.install('https://1tqs0.lthbzyb.com/c0ren');
      }else {
        Toast('当前非IOS环境')
      }
    },
    downloadAndroid() {
      var userAgent = navigator.userAgent;
      var isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      var isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;
      if(isAndroid) {
        // window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.jackBrother.shande'
        window.location.href = 'https://www.pgyer.com/Jx3r'
      }else {
        Toast('当前非安卓环境')
      }
    }
  }
}
</script>
